<template>
  <div class="editList">
    <div style="background: #fff;padding: 30px;">
      <div class="diagnostic_header">
        <div class="editList_header">
          <h3>数转画像</h3>
          <div class="line"></div>
        </div>
        <div class="header_mian">
          <div class="h_left">
            <div class="hl_header">
              <div class="hl_score" id="score"></div>
              <div class="hl_txt">
                <h4>数字化水平评测</h4>
                <p>依据工业和信息化部发布《中小企业数字化水平评测指标（2022年版）》<span class="corporation">{{ evaluation.name }}</span> ，数字化水平达到<span class="red" v-if="evaluation.lv">{{evaluation.lv}}</span><span class="red" v-else>无等级</span></p>
              </div>
            </div>
            <div class="hl_performance">
              <h4>整体表现：</h4>
              <p>当前企业在<span class="red">{{ expression.weakness[0] }}、{{ expression.weakness[1] }}</span>方面较为薄弱，建议加强该板块的内容建设及数字化投入；在<span class="blue">{{ expression.protrude[0] }}、{{ expression.protrude[1] }}</span>方面较为突出，建议继续保持，加快实现全流程数字化转型。</p>
            </div>
          </div>
          <div class="h_right" id="chart1"></div>
        </div>
      </div>

<!--      echars图表-->
<!--      <div class="editList_chart">
        <div class="editList_item">
          <div class="editList_title">数字化基础</div>
          <div class="edit_chart" id="chart6"></div>
        </div>
        <div class="editList_item">
          <div class="editList_title">数字化经营</div>
          <div class="edit_chart" id="chart7"></div>
        </div>
        <div class="editList_item">
          <div class="editList_title">数字化管理</div>
          <div class="edit_chart" id="chart8"></div>
        </div>
        <div class="editList_item">
          <div class="editList_title">数字化成效</div>
          <div class="edit_chart" id="chart9"></div>
        </div>
      </div>-->
    </div>

    <div style="background: #fff;padding: 30px" class="btn_box">
      <!--  数转能力分析-->
      <div class="analysis_box">
        <div class="analysis_header">
          <h3>数转能力分析</h3>
          <div class="line"></div>
        </div>
        <div class="item_box">
          <div class="ab_item">
            <h4>数字化基础</h4>
            <div class="revolution_box">
              <div class="chart_box" id="chart2"></div>
              <div class="revolution_table">
                <el-scrollbar class="quest-scroll">
                  <ul>
                    <li v-for="(item,index) in foundation" :key="item.target_id2">
                      <div class="r_name">
                        <p>{{ item.name }}</p>
                        <p class="red">{{ item.value }}分</p>
                      </div>
                      <div class="r_txt">
                        <p>{{ getRandomComment(item.comment) }}</p>
                      </div>
                    </li>
                  </ul>
                </el-scrollbar>
              </div>
            </div>
          </div>
          <div class="ab_item">
            <h4>数字化经营</h4>
            <div class="revolution_box">
              <div class="chart_box" id="chart3"></div>
              <div class="revolution_table">
                <el-scrollbar class="quest-scroll">
                  <ul>
                    <li v-for="(item,index) in business" :key="item.target_id2">
                      <div class="r_name">
                        <p>{{ item.name }}</p>
                        <p class="red">{{ item.value }}分</p>
                      </div>
                      <div class="r_txt">
                        <p>{{ getRandomComment(item.comment) }}</p>
                      </div>
                    </li>
                  </ul>
                </el-scrollbar>
              </div>
            </div>
          </div>
          <div class="ab_item">
            <h4>数字化管理</h4>
            <div class="revolution_box">
              <div class="chart_box" id="chart4"></div>
              <div class="revolution_table">
                <el-scrollbar class="quest-scroll">
                  <ul>
                    <li v-for="(item,index) in manage" :key="item.target_id2">
                      <div class="r_name">
                        <p>{{ item.name }}</p>
                        <p class="red">{{ item.value }}分</p>
                      </div>
                      <div class="r_txt">
                        <p>{{ getRandomComment(item.comment) }}</p>
                      </div>
                    </li>
                  </ul>
                </el-scrollbar>
              </div>
            </div>
          </div>
          <div class="ab_item">
            <h4>数字化成效</h4>
            <div class="revolution_box">
              <div class="chart_box" id="chart5"></div>
              <div class="revolution_table">
                <el-scrollbar class="quest-scroll">
                  <ul>
                    <li v-for="(item,index) in effect" :key="item.target_id2">
                      <div class="r_name">
                        <p>{{ item.name }}</p>
                        <p class="red">{{ item.value }}分</p>
                      </div>
                      <div class="r_txt">
                        <p>{{ getRandomComment(item.comment) }}</p>
                      </div>
                    </li>
                  </ul>
                </el-scrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  解决方案:富文本-->
      <div class="solution_box">
        <div class="solution_header">
          <h3>解决方案</h3>
          <div class="line"></div>
        </div>
        <div class="solution_main">
          <div class="solution_sc">
            <el-upload
                ref="upload"
                class="upload-demo"
                action="/file/upload_single_file"
                :on-change="handleChange"
                :on-success="uploadSuccess"
                :before-remove="beforeRemove"
                :file-list="fileList">
              <el-button size="small" type="primary" v-if="uploadButton">上传附件</el-button>
              <el-button size="small" type="primary" @click="reupload" v-else-if="!uploadButton">重新上传</el-button>
            </el-upload>
          </div>
          <div class="solution_rich">
            <quill-editor  ref="myQuillEditor" v-model="content" :options="editorOption"
                           class="my-quill-editor"></quill-editor>
          </div>
        </div>
      </div>
    </div>

    <div style="background: #fff;padding: 30px;margin-top: 20px">
      <!--  产品推荐-->
      <div class="recommended_box">
        <div class="recommended_header">
          <h3>产品推荐</h3>
          <div class="line"></div>
        </div>
        <div class="recommended_main">
          <button class="recommended_btn" @click="selectProduct">选择产品</button>
          <!--          无数据-->
          <div class="no_data" v-if="!selectedProdcut.length">
            <div class="img_box">
              <img src="@/assets/statistics/NoData_icon@2x.png" alt="">
            </div>
            <p>暂无数据</p>
          </div>
          <div v-else-if="selectedProdcut.length">
            <div class="main_table table_tt">
              <table border="0" cellspacing="0" cellpadding="0">
                <thead>
                <tr>
                  <th width="55%">产品信息</th>
                  <th>价格</th>
                  <th>推荐指数</th>
                  <th width="8%">操作</th>
                </tr>
                </thead>
              </table>
            </div>
            <div class="list2">
              <el-scrollbar class="list-scroll">
                <div class="main_table table_bd" style="max-height: 580px">
                  <table border="0" cellspacing="0" cellpadding="0">
                    <tbody>
                    <tr v-for="(item,index) in selectedProdcut" :key="item.id">
                      <td width="55%">
                        <div class="product_info">
                          <div class="r_logo">
                            <img :src="item.product_img[0]" alt="">
                          </div>
                          <div class="r_content">
                            <h4>{{ item.name }}</h4>
                            <div class="tag_box">
                              <div v-for="(item1,index) in item.tags" :key="index">{{ item1 }}</div>
                            </div>
                            <p>{{ item.feature }}</p>
                          </div>
                        </div>
                      </td>
                      <td class="red">￥{{ item.price }}元/年</td>
                      <td>
                        <el-rate
                            v-model="item.star"
                            text-color="#ff9900"
                            score-template="{value}">
                        </el-rate>
                      </td>
                      <td width="8%">
                        <span style="cursor: pointer" class="blue" @click="removeItem(index)">取消选择</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </el-scrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="background: #fff;padding: 30px" class="btn_box">
      <a class="blue" href="JavaScript:;" @click="submit">提交</a>
      <a @click="previewReport" class="green">预览报告</a>
      <router-link to="/enterpriseList">返回</router-link>
    </div>

<!--    弹窗-->
    <el-dialog class="select_product"
        title="选择产品"
        :visible.sync="dialogVisible"
        width="30%"
               :key="openNum"
        :before-close="handleClose"
    >
      <div class="dialog_btn">
        <div class="item_btn">
          <span style="margin-right: 10px">产品分类</span>
<!--          <el-cascader class="lv"-->
<!--              v-model="value3"-->
<!--              :options="options"-->
<!--              :props="{ label: 'name' }"-->
<!--              @change="handleChange"></el-cascader>-->
          <el-cascader v-model="typeText" :options="proClass" :props="{value: 'id', label: 'name'}" clearable></el-cascader>
        </div>
        <div class="item_btn">
          <span>产品名称</span>
          <el-input style="width: 240px;margin-left: 12px" v-model="nameText" placeholder="输入产品名称" clearable></el-input>
        </div>
        <button @click="search">查询</button>
      </div>
      <div class="dialog_table">
        <el-table
            ref="multipleTable"
            :data="tableData"
            :border="true"
            style="width: 100%"
            @selection-change="handleSelectionChange">
          <el-table-column
              type="selection"
              width="60"
              align="center">
          </el-table-column>
          <el-table-column label="产品信息" width="700px%">
            <template slot-scope="scope">
              <div class="product_info">
                <div class="r_logo">
                  <img :src="scope.row.product_img[0]" alt="">
                </div>
                <div class="r_content">
                  <h4>{{ scope.row.name }}</h4>
                  <div class="tag_box">
                    <div v-for="(item,index) in scope.row.tags" :key = 'index'>{{ item }}</div>
                  </div>
                  <p>{{ scope.row.feature}}</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="address"
              label="价格">
            <template slot-scope="scope">
              <div class="price">
                ￥{{ scope.row.price }}元/年
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="operate">
          <div class="handle">
            <div class="all-select">
              <el-checkbox :indeterminate="isIndeterminate" v-model="allSelect" @change="toggleSelection()"></el-checkbox>
            </div>
            <div class="button" @click="toggleSelection()">全选</div>
<!--            <div class="button">下载答卷</div>-->
          </div>
          <el-pagination
              :key="openNum"
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[4, 8, 12, 16]"
              :page-size='limit'
              layout="prev, pager, next, sizes"
              :total='total'
          >
          </el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="verifyData">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import address from "../questionnaire/components/address";
import {cate_list, company_nalysis, companyInfo} from "../../request/oneEnterOneFileApi";

export default {
  name: "editList",
  data(){
    return {
      content: '',
      score:10,
      dictionary_type:'',
      uploadButton:true,//解决方案按钮
      business:[],
      effect:[],
      foundation:[],
      manage:[],
      editorOption: {
        // 占位配置
        placeholder: '请输入...',
        height: '300px',
        modules: {
          // 配置工具栏
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
            ['blockquote', 'code-block'], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
            [{ script: 'sub' }, { script: 'super' }], // 上标/下标
            [{ indent: '-1' }, { indent: '+1' }], // 缩进
            [{ direction: 'rtl' }], // 文本方向
            ['link', 'image', 'video'], // 链接、图片、视频
            [{ align: [] }], // 添加居中按钮
            [{ color: [] }], // 文字颜色按钮
          ]
        }
      },
      value3:'',
      input:'',
      options:[],
      currentPage:1,
      dialogVisible:false,
      evaluation:{ //测评信息
        name:'重庆龙易购科技有限公司',
        lv:'二级'
      },
      expression:{ //整体表现：薄弱与突出
        weakness:'数字化基础、数字化经营',
        protrude:'数字化管理、数字化成效'
      },
      recommended:[],
      chart1 : [],
      chart2 : [],
      chart3 : [],
      chart4 : [],
      chart5 : [],
      fileList: [],
      checkBOx:[],
      tableData: [

      ], // 表格数据
      total:null,
      limit:4,
      company_id:'',
      isIndeterminate: false, // 表格是否有选中但未全选
      allSelect: false, // 表格全选
      multipleSelection: [], // 表格选中列表
      selectedProdcut:[],
      allselectData:[],
      page:1,
      typeText: [],//产品分类
      nameText: '',//产品名称
      proClass: [],//分类数据
      openNum:1,
      whetherToSwitchPages: false
    }
  },
  mounted() {
    // this.options = address;
    this.obtainData();
    this.getPorClass();
    this.company_id = this.$route.query.company_id
    console.log(this.company_id)
    this.$api.cate_list().then(res=>{
      this.options = res.data
    })
    //获取关联产品
    this.$api.Full({report_id:this.$route.query.company_id}).then(res=>{
      console.log(res,888)
      this.selectedProdcut = res.data.recommend.map(item => {
        // 将 star 属性从 item 中解构出来
        const { star, product } = item;
        // 将 star 属性添加到 product 对象中，并转换为数字类型
        return {
          ...product,
          star: Number(star)
        };
      });
      //获取选中产品的id
      let CheckArray = [];

      this.selectedProdcut.forEach(item => {
        CheckArray.push(item.id);
      });
      this.checkBOx = CheckArray

      console.log(this.recommended,889)
    })
  },
  methods:{
    previewReport(){
      const routeData = this.$router.resolve({
        path: '/pdfReport',
        query: {
          id: this.company_id,
          content: this.content,
          selectedProduct: JSON.stringify(this.selectedProdcut)
        }
      });
      window.open(routeData.href, '_blank');
    },
    //产品分类
    getPorClass() {
      this.$api.cateList().then(res=>{
        this.proClass = res.data;
      })
    },
    getRowKeys(row){
      return row.id
    },
    getRandomComment(commentArray) {
      // 随机生成一个索引
      const randomIndex = Math.floor(Math.random() * commentArray.length);
      // 返回随机选择的评论
      return commentArray[randomIndex];
    },
    leida(nameList,valueList,color,id,name) {
      const myChart = this.$echarts.init(
          document.getElementById(id)
      )
      myChart.setOption({
        tooltip: {
          trigger: "item",
          backgroundColor: "rgb(255,255,255)",
          borderColor: 'rgb(255,255,255)',
          extraCssText: 'box-shadow: 0 0 15px rgba(0,0,0,0.15);border-radius: 4px;',
          textStyle: {
            color: "#23282d", // 设置字体颜色
            fontSize: 12, // 设置字体大小
          },
          formatter: function(params) {
            var result = '<p>'+ params.name +'</p>';
            params.value.forEach(function(val, index) {
              var circleColor = color[0];
              // 自定义 tooltip 的内容，包括小圆球的颜色和数据项的名称及其值
              result += '<p style="margin-top: 7px">' + '<span style="display:inline-block;margin-right:5px;border-radius:8px;width:8px;height:8px;background-color:' + circleColor + ';"></span>' + '<span style="color: #3c4248;margin-right: 10px">' + nameList[index].name + '</span>' + '<span style="color: '+circleColor+';font-family: DINR;font-weight: 500">' + val + '</span>' + '</p>';
            });
            return result;
          }
        },
        radar: [
          {
            indicator: nameList,
            center: ["50%", "50%"],
            radius: '70%',
            name: {
              textStyle: {
                color: '#23282d',
                fontSize: 12,
              },
              formatter: function(value, indicator) {
                let weight = indicator.value;
                return '{a|' + value + '}' +'\n' +'{b|权重' + weight +'}';
              },
              rich: {
                a: {
                  color: '#23282d',
                },
                b: {
                  color: '#909DA5',
                  lineHeight: 20, // 调整数值距离名称的距离
                },
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgb(228,231,233)",
                opacity: 1,
                width: 1,
              },
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: "rgb(228,231,233)",
                opacity: 0,
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(228,231,233,1)",
                type: "solid",
              },
            },
          },
        ],
        series: [
          {
            type: "radar",
            symbol: 'circle',
            symbolSize: 8,
            tooltip: {
              trigger: "item",
            },
            data: [
              {
                value: valueList,
                name: name,
              },
            ],
            itemStyle: {
              normal: {
                borderColor: color[0],
                borderWidth: 0.8,
                color:'rgb(255,255,255)'
              },
            },
            lineStyle: {
              normal: {
                color: color[0],
                width: 2,
              },
            },
            areaStyle: {
              normal: {
                color: color[0],
                opacity: 0.2,
              },
            },
          },
        ],
      })
    },
    instrument(value){
      const score = value.toFixed(1)
      const myChart = this.$echarts.init(
          document.getElementById('score')
      )
      const startAngle = 450
      const endAngle = -60
      myChart.setOption(
          {
            title: [
              {
                text: `{value|${score}}{name|${'分'}}`,
                left: 'center',
                bottom: '35%',
                textStyle: {
                  rich: {
                    value: {
                      fontSize: '30',
                      color: '#3984F5',
                      padding: [4, 0]
                    },
                    name: {
                      fontSize: '30',
                      color: '#3984F5'
                    }
                  }
                }
              }
            ],
            series: [
              {
                name: '内层带指针',
                type: 'gauge',
                radius: '100%',
                startAngle,
                endAngle,
                axisLine: {
                  lineStyle: {
                    color: [[1, '#EBF4FF']],
                    width: 5
                  }
                },
                axisTick: {
                  show: false
                },
                splitLine: {
                  show: false
                },
                axisLabel: {
                  show: false
                },
                detail: {
                  show: false
                },
                pointer: {
                  show: true,
                  length: '22%',
                  offsetCenter: [0, '-78%'],
                  width: 5,
                  icon: 'path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z',
                  itemStyle: {
                    color: {
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: '#4576ff'
                        },
                        {
                          offset: 0.2,
                          color: '#4576ff'
                        },
                        {
                          offset: 0.8,
                          color: '#4576ff'
                        },
                        {
                          offset: 1,
                          color: '#4576ff'
                        }
                      ]
                    }
                  }
                },
                progress: {
                  show: true,
                  width: 5,
                  itemStyle: {
                    color: {
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: '#4576ff'
                        },
                        {
                          offset: 1,
                          color: '#3961d2'
                        }
                      ]
                    }
                  }
                },
                data: [
                  {
                    value: score
                  }
                ]
              },
              {
                name: '中心效果圆',
                type: 'gauge',
                radius: '78%',
                startAngle: 0,
                endAngle: 360,
                axisLine: {
                  lineStyle: {
                    color: [[1, '#EBF4FF']],
                    width: 2,
                    shadowBlur: 40, // 设置阴影模糊程度
                    shadowColor: 'rgb(30,139,222)' // 设置阴影颜色
                  }
                },
                axisTick: {
                  show: false
                },
                splitLine: {
                  show: false
                },
                axisLabel: {
                  show: false
                },
                detail: {
                  show: false
                },
                pointer: {
                  show: false
                },
                progress: {
                  show: false,
                  width: 8,
                  itemStyle: {
                    color: {
                      type: 'radial',
                      x: 0.5,
                      y: 0.5,
                      r: 0.5,
                      colorStops: [
                        {
                          offset: 0,
                          color: 'rgb(0, 224, 205, 0)'
                        },
                        {
                          offset: 0.1,
                          color: 'rgb(0, 224, 205, 0)'
                        },
                        {
                          offset: 0.3,
                          color: 'rgb(0, 224, 205, 0)'
                        },
                        {
                          offset: 0.4,
                          color: 'rgb(0, 224, 205, 0.05)'
                        },
                        {
                          offset: 0.5,
                          color: 'rgb(0, 224, 205, 0.1)'
                        },
                        {
                          offset: 1,
                          color: 'rgb(0, 224, 205, 0.3)'
                        }
                      ],
                    }
                  }
                },
                data: [
                  {
                    value: 100
                  }
                ]
              }
            ]
          }
      )

    },
    reupload(){ //重新上传
      this.$refs.upload.clearFiles();
      this.content = ''
    },
    handleChange() {
      // 只保留最新上传的文件，删除之前的文件

    },
    uploadSuccess(response, file, fileList){ //上传成功之后的回调
      this.uploadButton = false;
      this.$api.company_nalysis({file_url:response.data.file_path}).then(res=>{
        let combinedData = '';
        for (let i = 0; i < res.data.length; i++) {
          combinedData += res.data[i] + ', ';
        }
        this.content = combinedData
        console.log(this.content)
      })
    },
    beforeRemove(file, fileList) {
      this.uploadButton = true
    },
    submit(){ //提交
      const productIds = [];
      const stars = [];
      let id = this.$route.query.company_id;

      for (let i = 0; i < this.selectedProdcut.length; i++) {
        const { id, star } = this.selectedProdcut[i];
        productIds.push(id);
        stars.push(star);
      }

      let data = {
        report_id:id,
        solution:this.content,
        product_id:productIds,
        star:stars
      }

     this.$api.saveMethod(data).then(res=>{
       if (res.status == 200){
         this.$message({
           message: '提交成功',
           type: 'success'
         });
         this.$router.push('/diagnosticReport')
       }
       console.log(res)
     })
    },
    initializeStar() {
      this.selectedProdcut.forEach(item => {
        this.$set(item, 'star', 5); // 使用 Vue.set 或 this.$set 方法确保属性的响应性
      });
    },
    verifyData(){ //确认弹窗 确定的时候添加数据
      this.dialogVisible = false
      this.selectedProdcut = this.multipleSelection
      this.initializeStar(); // 初始化 star 属性
      // if (!this.selectedProdcut.length){
      // }else {
      //   this.multipleSelection.forEach(item => {
      //     const exists = this.selectedProdcut.some(selectedItem => selectedItem.id === item.id);
      //     if (!exists) {
      //       this.selectedProdcut.push(item);
      //       this.initializeStar(); // 初始化 star 属性
      //       console.log(this.selectedProdcut,987)
      //     }
      //   });
      // }
      //获取选中产品的id
      // let CheckArray = [];
      //
      // this.selectedProdcut.forEach(item => {
      //   CheckArray.push(item.id);
      // });
      // this.checkBOx = CheckArray
    },
    removeItem(index) { //移出当前数据
      this.selectedProdcut.splice(index, 1);
    },
    handleClose(){
      this.dialogVisible = false;
      ++this.openNum;
    },//弹窗关闭
    handleSelectionChange(val) {
      console.log(this.whetherToSwitchPages, '2222222222222')
      if (this.whetherToSwitchPages) {
        this.whetherToSwitchPages = false;
        return false;
      }
      this.allselectData[this.page - 1] = val;
      console.log(this.allselectData, this.page, 'allselectData')
      let list = [];
      // list.push(...this.selectedProdcut);
      this.allselectData.forEach(item => {
        item.forEach(i => {
          list.push(i)
        })
      })
      // console.log(list, 'list')

      this.multipleSelection = [...new Set(list)];

      console.log(this.multipleSelection)
      this.isIndeterminate = val.length > 0 && val.length < this.tableData.length;
      this.allSelect = val.length === this.tableData.length ? true : false;

    }, // 表格选中改变
    toggleSelection() {
      this.$refs.multipleTable.toggleAllSelection();
    }, // 表格全选
    toggleSelection1(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSizeChange(val){
      console.log(this.whetherToSwitchPages, '条数')
      this.whetherToSwitchPages = true;
      this.limit = val;
      this.getTableData()
    },
    handleCurrentChange(val){
      console.log(this.whetherToSwitchPages, '翻页')
      this.whetherToSwitchPages = true;
      this.page = val
      this.getTableData()
    },
    obtainData(){
      let id = this.$route.query.company_id;
      this.$api.companyInfo({company_id:id}).then(res=>{
        console.log(res,789)
        this.evaluation.name = res.data.company
        this.evaluation.lv = res.data.diagnose_level
        this.score = res.data.diagnose_score
        this.dictionary_type = res.data.dictionary_type
        this.instrument(parseFloat(this.score));
        this.$api.companyPortrayal({company_id:id,dictionary_type:this.dictionary_type}).then(res=>{

          /*整体表现的值*/
          let abc = res.data.entirety
          abc.sort((a, b) => a.value - b.value);
          let minValueObjects = abc.slice(0, 2);
          let maxValueObjects = abc.slice(-2);
          this.expression.weakness = minValueObjects.map(obj => obj.name);
          this.expression.protrude = maxValueObjects.map(obj => obj.name);

          this.business = res.data.business
          this.effect = res.data.effect
          this.foundation = res.data.foundation
          this.manage = res.data.manage
          // this.$forceUpdate();
          let nameList = [];
          let valueList = [];
          for (let i = 0; i < res.data.entirety.length; i++) {
            let nameData = {name: res.data.entirety[i].name, max: res.data.entirety[i].max || Number(res.data.entirety[i].value)+10,value:res.data.entirety[i].value};
            nameList.push(nameData);
            valueList.push(res.data.entirety[i].value);
          }

          let nameList1 = [];
          let valueList1 = [];
          for (let i = 0; i < res.data.foundation.length; i++) {
            let nameData = {name: res.data.foundation[i].name, max: res.data.foundation[i].max || Number(res.data.foundation[i].value)+10,value:res.data.foundation[i].value};
            nameList1.push(nameData);
            valueList1.push(res.data.foundation[i].value);
          }

          let nameList2 = [];
          let valueList2 = [];
          for (let i = 0; i < res.data.business.length; i++) {
            let nameData = {name: res.data.business[i].name, max: res.data.business[i].max || Number(res.data.business[i].value)+10,value:res.data.business[i].value};
            nameList2.push(nameData);
            valueList2.push(res.data.business[i].value);
          }

          let nameList3 = [];
          let valueList3 = [];
          for (let i = 0; i < res.data.manage.length; i++) {
            let nameData = {name: res.data.manage[i].name, max: res.data.manage[i].max || Number(res.data.manage[i].value)+10,value:res.data.manage[i].value};
            nameList3.push(nameData);
            valueList3.push(res.data.manage[i].value);
          }

          let nameList4 = [];
          let valueList4 = [];
          for (let i = 0; i < res.data.effect.length; i++) {
            let nameData = {name: res.data.effect[i].name, max: res.data.effect[i].max || Number(res.data.effect[i].value)+10,value:res.data.effect[i].value};
            nameList4.push(nameData);
            valueList4.push(res.data.effect[i].value);
          }

          const _color = ['rgb(227,35,37)']
          const _color1 = ['rgb(91,124,253)']
          const _color2 = ['rgb(26,207,155)']
          const _color3 = ['rgb(134,97,254)']
          /*this.leida(nameList1,valueList1,_color,'chart6','数字化基础')
          this.leida(nameList2,valueList2,_color1,'chart7','数字化经营')
          this.leida(nameList3,valueList3,_color2,'chart8','数字化管理')
          this.leida(nameList4,valueList4,_color3,'chart9','数字化成效')*/
          this.leida(nameList,valueList,_color,'chart1','总览')
          this.leida(nameList1,valueList1,_color,'chart2','数字化基础')
          this.leida(nameList2,valueList2,_color1,'chart3','数字化经营')
          this.leida(nameList3,valueList3,_color2,'chart4','数字化管理')
          this.leida(nameList4,valueList4,_color3,'chart5','数字化成效')

        })
      })
    },
    selectProduct(){ //选择产品
      this.dialogVisible=true
      ++this.openNum;
      this.multipleSelection = this.selectedProdcut

      // this.allselectData = []
      // this.multipleSelection.length > 0 || this.multipleSelection.push(...this.checkBOx)
      this.page = 1;
      this.getTableData()
    },
    search() { //搜索
      this.page = 1;
      this.getTableData()
    },
    getTableData() {
      let data = {
        page: this.page,
        limit: this.limit,
        name: this.nameText,
        cate_node_id: this.typeText.length>0?this.typeText[this.typeText.length-1]:'',
        status:1
      }
      this.$api.product_list(data).then(res=>{
        this.tableData = res.data.data
        this.total = res.data.count
        this.$nextTick(() => {
          console.log(this.multipleSelection, 'this.multipleSelection')
          const checkedList = this.tableData.filter(item => {
            return this.multipleSelection.map(selectedItem => selectedItem.id).includes(item.id)
          })
          this.toggleSelection1(checkedList)
        })
      })
    }
  },
}
</script>

<style scoped lang="scss">
.editList{
  margin: 18px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  h3{
    position: relative;
    margin-right: 20px;
    flex-shrink: 0;
    height: 18px;
    line-height: 18px;
    font-weight: 400;
    font-size: 18px;
    color: #242D33;
    &:before{
      content: '';
      width: 4px;
      height: 20px;
      background: #3984F5;
      position: absolute;
      top: 0;
      left: -30px;
    }
  }
  .diagnostic_header{
    .editList_header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .line{
        background: #EDF1F4;
        flex-grow: 1;
        height: 1px;
      }
    }
    .look_out{
      color: #909DA5;
    }
    .header_mian{
      display: flex;
      margin-top: 42px;
      .h_left{
        flex-grow: 1;
        .hl_header{
          display: flex;
          align-items: center;
          margin-top: 64px;
          .hl_score{
            width: 136px;
            height: 136px;
            margin-right: 51px;
            flex-shrink: 0;
          }
          .hl_txt{
            h4{
              color: #242D33;
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              margin-bottom: 20px;
            }
            p{
              color: #242D33;
              font-size: 14px;
              max-width: 564px;
              .corporation{
                font-weight: bold;
              }
              .red{
                color: #FD5451;
              }
            }
          }
        }
        .hl_performance{
          h4{
            color: #242D33;
            font-size: 16px;
            margin-bottom: 15px;
            margin-top: 90px;
          }
          p{
            font-size: 14px;
            max-width: 828px;
            color: #242D33;
            .red{
              color: #FD5451;
            }
            .blue{
              color: #3984F5;
            }
          }
        }
      }
      .h_right{
        width: 720px;
        height: 400px;
        background: #FAFCFE;
      }
    }
  }
  .editList_chart{
    display: flex;
    justify-content: space-between;
    .editList_item{
      width: 394px;
      height: 400px;
      background: #FAFCFE;
      margin-top: 20px;
      .editList_title{
        font-weight: bold;
        font-size: 14px;
        color: #242D33;
        padding: 24px 0 0 24px;
      }
      .edit_chart{
        width: 394px;
        height: 352px;
      }
    }
  }
  .analysis_box{
    .analysis_header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .line{
        background: #EDF1F4;
        flex-grow: 1;
        height: 1px;
      }
    }
    .item_box{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 14px;
    }
    .ab_item{
      width: 49.5%;
      height: 400px;
      background: #FAFCFE;
      margin-top: 16px;
      padding: 24px;
      box-sizing: border-box;
      h4{
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 14px;
        color: #242D33;
      }
      .revolution_box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .chart_box{
          flex-grow: 1;
          height: 329px;
        }
        .revolution_table{
          width: 380px;
          ul{
            border: 1px solid #E1E8EC;
          }
          li{
            display: flex;
            align-items: center;
            border-bottom: 1px solid #E1E8EC;
            padding: 20px 0 19px 0;
            box-sizing: border-box;
            &:last-child{
              border-bottom: none;
            }
            .r_name{
              text-align: center;
              width: 20%;
              font-weight: bold;
              font-size: 12px;
              color: #242D33;
              .red{
                color: #FD5451;
                font-weight: 400;
                font-size: 14px;
              }
            }
            .r_txt{
              width: 340px;
              box-sizing: border-box;
              min-height: 39px;
              p{
                color: #242D33;
                line-height: 19px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  .solution_box{
    margin-top: 40px;
    .solution_header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .line{
        background: #EDF1F4;
        flex-grow: 1;
        height: 1px;
      }
    }
    .solution_main{
      margin-top: 22px;
      .solution_sc{
        display: flex;
        align-items: center;
      }
      .reupload{
        height: 40px;
        width: 120px;
        margin-left: 10px;
      }
      .upload-demo{
        display: flex;
        align-items: center;
        .el-button{
          height: 40px;
          width: 120px;
        }
        ::v-deep .el-upload-list{
          background: #F6F7F9;
          padding-bottom: 8px;
          width: 620px;
          margin-left: 10px;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      .solution_rich{
        margin-top: 20px;
        width: 1624px;
        height: 600px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #CEDAE0;
      }
    }
  }
  .recommended_box{
    .recommended_header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .line{
        background: #EDF1F4;
        flex-grow: 1;
        height: 1px;
      }
    }
    .recommended_main{
      .recommended_btn{
        width: 120px;
        height: 40px;
        background: #3984F5;
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 36px;
        margin-top: 30px;
        margin-bottom: 20px;
      }
      .main_table {
        font-size: 14px;
        .filtrate {
          margin-bottom: 20px;
          .down_select {
            float: left;
            margin-right: 10px;
          }
          .btn {
            float: left;
            width: 70px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            border-radius: 2px;
            color: #fff;
            background-color: #1190f3;
            &:hover {
              background-color: #0073cc;
            }
          }
        }
        table {
          width: 100%;
          table-layout: fixed;
          text-align: left;
          border-top: 1px solid #dbe1e5;
          border-right: 1px solid #dbe1e5;
          word-wrap: break-word;
        }
        tr:hover {
          background-color: #f6fbfe;
        }
        th {
          background-color: #f6f7f9;
          line-height: 22px;
          border-bottom: 1px solid #dbe1e5;
          border-left: 1px solid #dbe1e5;
          padding: 8px 16px;
          font-weight: 400;
          color: #6C7880;
        }
        td {
          padding: 9px 16px;
          border-bottom: 1px solid #dbe1e5;
          border-left: 1px solid #dbe1e5;
          line-height: 22px;
        }
        .oe1 {
          display: inline-block;
          vertical-align: top;
          max-width: 100%;
        }
        .align_l {
          text-align: left;
        }
        .align_c {
          text-align: center;
        }
        .red {
          color: #fd5451;
        }
        .blue {
          color: #1190f3;
        }
        .operate {
          a {
            margin-right: 5%;
          }
        }
        .all_operate {
          margin-top: 20px;
          .btn {
            padding: 2px 0;
            .checkbox {
              margin: 5px 16px;
            }
            a {
              float: left;
              line-height: 26px;
              padding: 0 10px;
              border: 1px solid #b8c1c9;
              border-radius: 2px;
              color: #60696f;
              margin-right: 10px;
              &:hover {
                color: #0073cc;
                border-color: #0073cc;
              }
            }
          }
        }
      }
      .product_info{
        display: flex;
        align-items: center;
        .r_logo{
          margin-top: 20px;
          width: 96px;
          height: 96px;
          margin-right: 20px;
          flex: 0 0 auto;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .r_content{
          margin-top: 10px;
          flex-grow: 1;
          h4{
            font-weight: 400;
            font-size: 16px;
            color: #242D33;
            line-height: 36px;
          }
          .tag_box{
            overflow: hidden;
            div{
              float: left;
              padding: 7px 11px 8px 10px;
              color: #3984F5;
              background: #EFF5FF;
              margin-right: 8px;
              font-size: 12px;
            }
          }
          p{
            margin-top: 7px;
            font-weight: 400;
            font-size: 12px;
            color: #6C7880;
            line-height: 18px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .no_data{
      border: 1px solid #DCE5EC;
      padding-top: 50px;
      padding-bottom: 40px;
      .img_box{
        margin: 0 auto;
        width: 240px;
        height: 150px;
      }
      img{
        width: 100%;
        height: 100%;
      }
      p{
        width: 100%;
        margin-top: 48px;
        text-align: center;
      }
    }
  }
}
.btn_box{
  margin-top: 18px;
  a{
    display: inline-block;
    width: 120px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #EEF1F3;
    border-radius: 4px;
    margin-right: 10px;
    font-weight: 400;
    font-size: 14px;
    color: #6C7880;
  }
  .blue{
    background: #3984F5;
    color: #fff;
  }
  .green{
    background: #04CD83;
    color: #fff;
  }
}
.select_product{
  ::v-deep .el-dialog{
    width: 50% !important;
  }
  .dialog_btn{
    display: flex;
    align-items: center;
    .lv{
      margin-left: 12px;
    }
    .item_btn{
      display: flex;
      align-items: center;
      margin-right: 30px;
    }
    button{
      width: 64px;
      height: 34px;
      background: #3984F5;
      border-radius: 4px;
      text-align: center;
      font-weight: 400;
      font-size: 12px;
      color: #FFFFFF;
      line-height: 24px;
    }
  }
  .operate {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .handle {
      display: flex;
      .all-select {
        margin-right: 16px;
        padding-left: 24px;
        line-height: 32px;
      }
      .button {
        margin-right: 10px;
        padding: 0 12px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #6C7880;
        border: 1px solid #CEDAE0;
        cursor: pointer;
        &:hover {
          color: #3984F5;
          border-color: #3984F5;
        }
      }
    }
  }
  ::v-deep .el-table {
    margin-top: 20px;
    font-size: 12px;
    color: #242D33;
    border-color: #DCE5EC;
    &::before,
    &::after {
      background-color: #DCE5EC;
    }
    .el-table__cell {
      padding: 8px 0;
      border-color: #DCE5EC;
    }
    .cell {
      line-height: 24px;
    }
    thead {
      color: #242D33;
    }
    th.el-table__cell.is-leaf {
      border-color: #DCE5EC;
      background: #F6F7F9;
    }
    th {
      font-weight: 400;
    }
    .el-table-column--selection .cell {
      padding-right: 10px;
    }
    .el-table__body tr:hover > td.el-table__cell {
      background-color: #F6FBFE;
    }
    td.el-table__cell {
      padding: 13px 0;
    }
  }
  .product_info{
    display: flex;
    align-items: center;
    .r_logo{
      //margin-top: 20px;
      width: 84px;
      height: 84px;
      margin-right: 20px;
      flex: 0 0 auto;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .r_content{
      //margin-top: 10px;
      flex-grow: 1;
      h4{
        font-weight: 400;
        font-size: 16px;
        color: #242D33;
        line-height: 36px;
      }
      .tag_box{
        overflow: hidden;
        div{
          float: left;
          padding: 7px 11px 8px 10px;
          color: #3984F5;
          background: #EFF5FF;
          margin-right: 8px;
          font-size: 12px;
        }
      }
      p{
        margin-top: 7px;
        font-weight: 400;
        font-size: 12px;
        color: #6C7880;
        line-height: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .price{
    color: red;
  }
}
::v-deep .quest-scroll {
  height: 320px;
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .el-scrollbar__bar.is-vertical {
    width: 4px;
  }
  .el-scrollbar__thumb {
    background: #A7B2B8;
  }
}
::v-deep .list-scroll {
  //height: 580px;
  .el-scrollbar__wrap {
    //overflow-x: hidden;
  }
  .el-scrollbar__bar.is-vertical {
    width: 4px;
  }
  .el-scrollbar__thumb {
    background: #A7B2B8;
  }
}
//编辑器
::v-deep .ql-editor {
  min-height: 300px;
  background-color: #fff;
}
.my-quill-editor{
  height: 560px;
  margin-bottom: 60px;
}
</style>